.wishListContainer {
    width: 88%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
}

.titleSection h1 {
    color: #000;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 8px;
}

.titleSection p {
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 40px;
}

.headerSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.buttonWrapper {
    display: flex;
    gap: 8px;
}

.removeAllButton {
    width: 150px;
    height: 45px;
    border-radius: 6px;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    background-color: white;
    border: 1px solid #FF2B2B;
    color: #FF2B2B;
}

@media (max-width: 900px) {
    .headerSection {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 40px;
    }

    .titleSection p {
        margin-bottom: 18px;
    }

    .titleSection h1 {
        font-size: 28px;
    }

    .titleSection p {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .titleSection h1 {
        font-size: 24px;
    }

    .buttonWrapper {
        flex-wrap: wrap;
    }
}

.wishListCards .cardsOuter {
    width: 100% !important;
}

@media (min-width: 1024px) {
    .wishListContainer {
        margin-top: 40px;
        margin-bottom: 48px;
    }
}