.eventsOuter {
  display: flex;
  flex-direction: column;
  height: 488px;
  width: 100%;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 16px;
}

.titleImage {
  width: 100%;
  height: 220px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  object-fit: cover;
}

@media (max-width: 1870px) {
  .eventsOuter {
    height: 540px;
  }
}

@media (max-width: 1600px) {
  .eventsOuter {
    height: 490px;
  }
}

@media (max-width: 770px) {
  .titleImage {
    height: 200px;
  }

  .eventsOuter {
    height: 460px;
  }
}

.titleSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 10px;
  margin-left: 16px;
  align-items: flex-start;
}

.titleSection h2 {
  color: black;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 500;
  width: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.reviewSection {
  display: flex;
  gap: 4px;
  align-items: center;
  margin-right: 18px;
}

.reviewSection img {
  width: 14px;
  height: 14px;
}

.reviewSection h3 {
  color: black;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 300;
}

.locationSection {
  display: flex;
  gap: 4px;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 8px;
  border-bottom: 1px #dedede solid;
  padding-bottom: 12px;
}

.locationSection h3 {
  color: #6b6969;
  font-size: 14px;
  font-family: Poppins;
  font-weight: 400;
}

.detailsSection {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin: 10px 16px;
}

.detailsWrapper {}

.detailsWrapper p {
  color: black;
  font-size: 14px;
  font-family: Poppins;
  font-weight: 500;
  width: auto;
  min-width: 50px;
  height: auto;
  padding: 10px;
  background: #f1f9fe;
  border-radius: 8px;
  text-align: center;
}


@media (max-width: 1080px) {
  .detailsWrapper p {
    font-size: 12px;
  }
}

@media (max-width: 700px) {
  .detailsWrapper p {
    font-size: 10px;
  }
}

.priceSection {
  display: flex;
  gap: 10px;
  position: absolute;
  bottom: 16px;
  left: 16px;
  align-items: center;
}

.priceSection h2 {
  color: #ff6666;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 600;
}

.priceSection h3 {
  color: #b1aeae;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 500;
  text-decoration: line-through;
}

.heartIcon {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}

@media (max-width: 630px) {
  .titleSection h2 {
    font-size: 14px;
  }

  .reviewSection h3 {
    font-size: 14px;
  }

  .locationSection h3 {
    font-size: 12px;
  }

  .priceSection h2 {
    font-size: 14px;
  }

  .priceSection h3 {
    font-size: 12px;
  }

  .titleImage {
    height: 170px;
  }

  .eventsOuter {
    height: 415px;
  }
}

@media (max-width: 609px) {
  .eventsOuter {
    height: 456px;
  }
}

@media (max-width: 540px) {
  .titleImage {
    height: 210px;
  }

  .eventsOuter {
    height: 432px;
  }
}

@media (max-width: 462px) {
  .eventsOuter {
    height: 430px;
  }
}

@media (max-width: 442px) {
  .eventsOuter {
    height: 450px;
  }
}

@media (max-width: 430px) {
  .titleImage {
    height: 180px;
  }

  .eventsOuter {
    height: 420px;
  }
}

@media (max-width: 301px) {
  .eventsOuter {
    height: 458px;
  }
}