.packageTitle{
    color: black;
font-size: 24px;
font-family: Poppins;
font-weight: 500;
margin-bottom: 24px;
}

.packageOuter{
    width: 88%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 80px;
}

.sliderOuter{
    position: relative;
}

.sliderArrow{
    position: absolute;
    top: 50%;
    right: -35px;
    transform: translateY(-50%);
    cursor: pointer;
    
}

@media (max-width: 1020px){
    .packageTitle{
    font-size: 22px;
    }

    .sliderArrow{
        display: none;
        
    }

}

@media (max-width: 767px){
    .packageOuter{
        margin-top: 60px;
    }
}

@media (max-width: 480px){
    .packageOuter{
        margin-top: 50px;
    }
}

