.venueSlider {
    width: 800px;
    /* height: 55%; */
    position: relative;
    border-radius: 16px;
}

.rightArrow {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 9;
    width: 32px;
}

.leftArrow {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 9;
    width: 32px;
}



@media (min-width: 767px) {
    .rightArrow {
        width: 52px;
    }

    .leftArrow {
        width: 52px;
    }
}


    .image{
        width: 100% !important;
        aspect-ratio: 800/440;
        border-radius: 16px;
        object-fit: cover;
    }

    .venueSlider{
        width: 100% !important;
    }


@media(min-width: 1024px){

    .image{
        width: 555px !important;
        aspect-ratio: 800/440;
        border-radius: 16px;
        object-fit: cover;
    }
}

@media(min-width: 1400px){
    .image {
        width: 800px !important;
        aspect-ratio: 800/440;
        border-radius: 16px;
        object-fit: cover;
        /* height: 364px; */
        /* aspect-ratio: 1024/364; */
    }
}

@media(min-width: 1700px){
    .image {
        width: 1000px !important;
        aspect-ratio: 800/440;
        border-radius: 16px;
        object-fit: cover;
        /* height: 364px; */
        /* aspect-ratio: 1024/364; */
    }
}