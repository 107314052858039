.paymentOuter {
  height: 155px;
  border-radius: 16px;
  border: 1px solid #e8e8e8;
  width: 100%;
  background: #fff;
  padding: 20px 31px 20px 24px;
  margin-bottom: 20px;
  
}

@media (max-width: 767px){
    .paymentOuter{
    margin-top: 20px;
    }
}

.paymentOuter h1 {
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 26px;
}

.onlineAndOffline{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 17px;
}

.onlineAndOffline img{
    width: 18px;
    height: 18px;
    cursor: pointer;
}
