.priceContainer {
  width: 100%;
  border-radius: 16px;
  border: solid 1px #e8e8e8;
  padding: 20px 0 24px 0;
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
}

.priceWrapper {
  width: 100%;
  /* border-radius: 16px;
    border: solid 1px #E8E8E8;
    padding: 20px 0 24px 0; */
  display: flex;
  flex-direction: column;
}

.sectionWrapper {
  padding: 0 24px 0 24px;
}

.heading {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 26px;
}

.subHeading {
  font-size: 0.875rem;
  font-weight: 300;
  margin-bottom: 21px;
  color: #5d5c5c;
}

.listRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 6px;
}

.rowTitle {
  font-size: 16px !important;
  font-weight: 400;
}

.checkboxClass {
  width: 16px !important;
  height: 16px !important;
}

.rowValueWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-align: right;
}

.rowValueWrapper .rowValue {
  font-size: 1rem;
  font-weight: 500;
  line-height: 30px;
  color: #ff6666;
}

.rowValueWrapper .rowValueGray {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  color: #f66;
}

.rowValueWrapper .rowStrikePrice {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 24px;
  text-decoration: line-through;
  color: #a2a2a2;
}

/* .breakLine {
    content: "";
    display: inline-block;
    width: 100%;
    height: 0.8px;
    background-color: #DEDEDE;
    margin-top: 12px;
    margin-bottom: 20px;
} */

.inputSection {
  display: flex;
  flex-direction: column;
  padding: 0 24px 0 24px;
}

.inputSection .inputLabel {
  font-size: 1rem;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 7px;
}

.cartButton {
  height: 50px !important;
  margin-top: 25px;
  font-size: 1.063rem;
}

.payInfo {
  display: flex;
  flex-direction: row;
  column-gap: 11px;
  margin-top: 16px;
}

.securityIcon {
  width: 12px;
  height: 12px;
  margin-left: 10px;
}

.words {
  color: #928f8f;
  font-size: 0.625rem;
  font-weight: 400;
  line-height: normal;
}

@media (min-width: 767px) {
  .priceContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    column-gap: 28px;
  }

  .priceWrapper {
    width: 100%;
  }

  .tabView {
    width: 35%;
    margin: auto 0;
    padding-right: 24px;
  }

  .cartButton {
    width: 100%;
  }

  .breakLine {
    margin-bottom: 7px;
  }

  .heading {
    font-size: 16px;
  }

  .rowTitle {
    font-size: 1rem !important;
  }

  .rowValueWrapper .rowValue {
    font-size: 1.25rem;
  }

  .rowValueWrapper .rowValueGray {
    font-size: 1.25rem;
  }

  .rowValueWrapper .rowStrikePrice {
    font-size: 1rem;
  }

  .securityIcon {
    width: 14px;
    height: 14px;
  }

  .words {
    font-size: 12px;
  }
}

@media (min-width: 1024px) {
  .priceContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    column-gap: 28px;
  }

  .priceWrapper {
    width: 100%;
  }

  .breakLine {
    margin-bottom: 20px;
  }

  .heading {
    font-size: 1rem;
  }

  .rowTitle {
    font-size: 1rem !important;
  }

  .rowValueWrapper .rowValue {
    font-size: 1.25rem;
    line-height: 30px;
  }

  .rowValueWrapper .rowValueGray {
    font-size: 1.25rem;
    line-height: 30px;
  }

  .rowValueWrapper .rowStrikePrice {
    font-size: 1rem;
    line-height: 24px;
  }

  .securityIcon {
    width: 20px;
    height: 20px;
  }

  .words {
    font-size: 1rem;
  }
}
