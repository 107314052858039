.profileWrapper {
  margin-bottom: 40px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.profileWrapper h1 {
  color: #000;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 40px;
}

.profileWrapper {
  width: 384px;
  margin-left: auto;
  margin-right: auto;
}

.profileForm {
  width: 100%;
}

.profileForm input {
  height: 50px;
  padding: 15px;
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.profileForm h4 {
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 7px;
}

.profileWrapper p {
  color: #f66;
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
  width: 100%;
  text-align: left;
  margin-left: 0 !important;
  cursor: pointer;
}

.profileWrapper button {

  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: #f66;
  color: #fff;
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  width: 183px;
  height: 50px;
  margin-top: 40px;
  left: 50%;
  transform: translateX(-50%);

}

@media (max-width: 500px) {
  .profileWrapper {
    width: 80vw;

  }
}

@media (max-width: 330px) {
  .profileForm h4 {
    font-size: 14px;
  }

  .profileForm input {
    height: 40px;
  }

  .profileWrapper p {
    font-size: 14px;
  }

  .profileWrapper button {
    margin-top: 25px;
    font-size: 14px;
    width: 140px;
    height: 40px;
  }
}