.modalWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modalWrapper h1 {
  color: #f66;
  text-align: center;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  margin-top: 36px;
  margin-bottom: 22px;
}

.shareRemarks {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  width: 331px;
  height: 43px;
  margin-bottom: 24px;
}

.description {
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 7px;
  width: 90%;
  text-align: left;
  margin-top: 24px;
}

.remarkvalue {
  width: 90%;
  resize: none;
  margin-bottom: 28px;
  padding: 12px;
}

.modalWrapper button {
  display: inline-flex;
  padding: 12px 60px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #f66;
  border: none;
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 20px;
  width: 178px;
  height: 48px;
}

@media (max-width: 390px){
  .modalWrapper h1{
    font-size: 24px;
  }

  .description {
    font-size: 14px;
  }

  .shareRemarks{
    width: 75vw;
  }

  .modalWrapper button {
    display: inline-flex;
    /* padding: 8px 40px; */
    align-items: center;
    font-size: 14px;
    margin-bottom: 22px;
  }
}

@media (max-width: 300px){
  .modalWrapper h1{
    font-size: 20px;
  }
}
