.cardsOuter {
  width: 78%;
}

/* .cardFlex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
} */

.cardFlex {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px; /* Adjust the gap as needed */
}


.eventCardWrapper {
  width: 100%;
}

.appliedFilters {
  display: none;
}

.errorText{
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

@media (max-width: 975px) {
  .appliedFilters {
    margin-top: 24px;
    margin-bottom: 29px;
    display: flex;
    gap: 20px;
  }

  .errorText{
    margin-top: 20px;
  }
}


.filterFlex {
  display: flex;
  gap: 9px;
  align-items: center;
  flex-wrap: wrap;
  height: auto;
  padding-bottom: 27px;
  border-bottom: 1px #e1dddd solid;
}

.filterContainer {
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: #ff6666;
  border-radius: 4px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 11px;
  display: inline-flex;
}

.filterContainer h5 {
  color: white;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 600;
}

.filterContainer img {
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: 0.3s all;
}

.filterContainer img:hover {
  transform: scale(1.4);
}

@media (max-width: 1600px) {
  .eventCardWrapper {
    width: 100%;
  }

  .cardFlex {
    grid-template-columns: repeat(3, 1fr);
  }
  
}

@media (max-width: 1200px) {
  .eventCardWrapper {
    width: 100%;
  }

  .cardFlex {
    grid-template-columns: repeat(2, 1fr);
  }
  

  .cardsOuter {
    width: 100%;
  }
}

@media (max-width: 975px){
  .appliedFilters button {
    width: 159px;
    height: 45px;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 4px;
    border: 1px #bdbdbd solid;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: inline-flex;
    color: black;
    font-size: 16px;
    font-family: Poppins;
    font-weight: 500;
    line-height: 21px;
  }
}

@media (max-width: 900px) {
  .cardsOuter {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }

 
}

@media (max-width: 540px) {
  .eventCardWrapper {
    width: 100%;
  }
  .cardFlex {
    grid-template-columns: repeat(1, 1fr);
  }
  
}

@media (max-width: 430px) {
  .eventCardWrapper {
    width: 100%;
  }
}

@media (max-width: 350px) {
  .eventCardWrapper {
    width: 100%;
  }
}
