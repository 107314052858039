.signinForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* row-gap: 0.8rem; */
  font-family: 'Poppins';
}

.signinForm h1 {
  color: #F66;
  font-family: Poppins;
  font-size: 32px;
  font-weight: 900;
  text-align: center;
}

.signinForm h2 {
  color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  margin: 28px 0 8px 0;
  text-align: center;
}

.signinForm p {
  display: flex;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: -0.10000000149011612px;
  text-align: left;
  align-items: center;
  color: #9a9ea7;
}

.signinForm p input {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.625rem;
}

.signinForm .inputField {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 20px;
}

.signinForm .inputField label {
  font-size: 17px;
  font-weight: 500;
}

.signinOptions {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.recoverPassword {
  color: #000;
  font-weight: 600;
  font-size: 0.875rem;
  cursor: pointer;
}

.sumbitButton {
  height: 3.188rem;
  font-size: 1rem;
  font-weight: 700;
  border-radius: 0.625rem;
}

.orText {
  width: 100% !important;
  text-align: center !important;
  justify-content: center;
}

.changeLoginType {
  border-radius: 0.375rem;
  border: none;
  padding: 0.563rem 1.25rem;
  font-family: Inter;
  font-size: 0.875rem;
  font-weight: 600;
  text-align: center;
  margin: 0;
  background-color: #fff;
  border: 1px solid #f2ad00;
  color: #f2ad00;
  cursor: pointer;
}

.signinForm .phoneInput {
  width: 100% !important;
  height: 3.375rem !important;
  font-size: 13px !important;
  height: 4.375rem !important;
}

.loginButton {
  height: 48px !important;
  margin-top: 40px;
}

.signinFooter {
  display: flex;
  font-family: 'Poppins';
  /* border-top: 1px solid #dee2e6; */
  /* height: 3.563rem; */
  /* font-size: 0.875rem; */
  margin-top: 28px;
}

.signinFooter p {
  margin: 0 auto;
  color: #000;
  font-weight: 600;
  font-size: 14px !important;
}

.signUpLink {
  padding: 0px 10px;
  font-weight: bold;
  cursor: pointer;
  color: #FF6666;
  font-size: 14px !important;
}

@media (max-width: 420px) {
  .signinForm h2 {
    margin: 16px 0 12px 0;
  }

  .signinForm h1 {
    font-size: 24px;
  }

  .signinForm .inputField {
    margin-top: 16px;
  }

  .signinForm .inputField label {
    font-size: 14px;
  }

  /* .signinOptions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  } */

  .recoverPassword {
    font-size: 12px;
  }

  .loginButton {
    margin-top: 30px;
  }

  .signinFooter {
    margin-top: 16px;
  }

  /* .signinFooter p {
    display: flex;
    flex-direction: column;
    text-align: center;
  } */
}

/* @media (min-width: 868px) {
  .signinForm .phoneInput {
    font-size: 16px !important;
  }
} */