.paymentOuter {
  height: auto;
  border-radius: 16px;
  border: 1px solid #e8e8e8;
  width: 100%;
  background: #fff;
  padding: 20px 31px 20px 24px;
  margin-bottom: 20px;
}

.eventSelect {
  width: 100%;
  height: 50px;
  /* border: 1px solid #f66; */
  font-size: 16px;
  border-radius: 6px;
  margin-bottom: 8px;
}

.peopleInput {
  width: 100%;
  height: 50px;
  /* border: 1px solid #f66; */
  font-size: 16px;
  border-radius: 6px;
  margin-bottom: 8px;
}

.remarkInput {
  width: 100%;
  height: 50px;
  /* border: 1px solid #f66; */
  font-size: 16px;
  border-radius: 6px;
  margin-bottom: 8px;
}

@media (max-width: 767px) {
  .paymentOuter {
    margin-top: 20px;
  }
}
