.packageOuter {
  width: 630px;
  height: 287px;
  /* box-shadow: -1px -1px 2px rgba(0, 0, 0, 0.08); */
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  display: flex;
  margin-right: 15px;
  padding-left: 28px;
}

.packageContent {
  width: 299px;
  position: relative;
}

.packageContent h2 {
  color: black;
  font-size: 20px;
  font-family: Poppins;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 14px;
  width: 251px;
  margin-top: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.packageContent ul {
  list-style-type: disc;
  margin-top: 12px;
  list-style-position: inside;
  width: 226px;


}

.packageContent ul li {
  color: #717171;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 400;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.packageLocation {
  display: flex;
  gap: 5px;
  border-bottom: 1px #dedede solid;
  padding-bottom: 14px;
}

.priceButton {
  border: none;
  background: #ff6666;
  border-radius: 8px;
  color: white;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 700;
  width: 124px;
  height: 41px;


}

.priceSection {
  position: absolute;
  bottom: 24px;
  display: flex;
  gap: 17px;
  align-items: center;
}

.priceSection p {
  color: #B1AEAE;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 600;
  text-decoration: line-through;
}

.packageImage {
  height: 100%;
}

.packageImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;

}

@media (max-width: 1024px) {

  .packageOuter {
    width: 563px;
    height: 261px;
    /* box-shadow: -1px -1px 2px rgba(0, 0, 0, 0.08); */
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    display: flex;
  }

  .packageContent {
    width: 262px;
  }

  .packageContent h2 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 12px;
    width: 214px;
  }

  .packageContent ul {
    list-style-type: disc;
    margin-top: 10px;
    list-style-position: inside;
    width: 226px;


  }

  .packageContent ul li {
    margin-bottom: 8px;
  }

  .packageLocation {
    display: flex;
    gap: 5px;
    padding-bottom: 12px;
    width: 198px;
  }

  .priceButton {
    border: none;
    background: #ff6666;
    border-radius: 8px;
    color: white;
    font-size: 16px;
    font-family: Poppins;
    font-weight: 700;
    width: 108px;
    height: 35px;


  }

  .priceSection {

    bottom: 16px;

  }

  .priceSection p {
    color: #B1AEAE;
    font-size: 16px;
    font-family: Poppins;
    font-weight: 600;
    text-decoration: line-through;
  }

  .packageImage {
    height: 100%;
  }

  .packageImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;

  }

}

@media (max-width: 830px) {

  .packageOuter {
    width: 530px;
    height: 248px;
    border-radius: 12px;
  }

  .packageContent {
    width: 242px;
  }

  .packageContent h2 {
    font-size: 16px;
    margin-bottom: 10px;
    width: 197px;
  }

  .packageContent ul {
    margin-top: 10px;
    list-style-position: inside;
    width: 200px;


  }

  .packageContent ul li {
    margin-bottom: 8px;
    font-size: 14px;
  }

  .packageLocation {
    display: flex;
    gap: 5px;
    padding-bottom: 12px;
    width: 198px;
  }

  .priceButton {
    border: none;
    background: #ff6666;
    border-radius: 8px;
    color: white;
    font-size: 16px;
    font-family: Poppins;
    font-weight: 700;
    width: 108px;
    height: 35px;


  }

  .priceSection {

    bottom: 15px;

  }

  .priceSection p {
    color: #B1AEAE;
    font-size: 12px;

  }

  .packageImage {
    height: 100%;
  }

  .packageImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;

  }

}