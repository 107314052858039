/* .paginationWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 28px;
    margin-bottom: 60px;
}

.paginationWrapper p{
    color: #FF6666;
font-size: 16px;
font-family: Poppins;
font-weight: 500;
cursor: pointer;
} */

.prevButton{
    color: #FF6666;
font-size: 16px;
font-family: Poppins;
font-weight: 500;
cursor: pointer;
background: #fafafa;
border: none;
margin-right: 28px;
}

.nextButton{
    color: #FF6666;
    font-size: 16px;
    font-family: Poppins;
    font-weight: 500;
    cursor: pointer;
    background: #fafafa;
    border: none;
    margin-left: 28px;
}

@media (max-width: 400px){
    .prevButton{
        margin-right: 20px;
    }

    .nextButton{
        margin-left: 20px;
    }
}