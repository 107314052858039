.footer {
  width: 100%;
  height: 17.25rem;
  /* margin-top: 10rem; */
  background-color: #323232;
  display: flex;
}

.footerContent {
  width: 68%;
  display: flex;
  justify-content: space-between;
  gap: 70px;
  margin: auto;
}

@media (max-width: 1300px) {
  .footerContent {
    width: 84%;
  }
}

@media (max-width: 950px) {
  .footerContent {
    width: 89%;
  }
}

.footerLeft {
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 30%;
}

.siteIcon {
  width: 131px;
  /* height: 29px; */
  /* border-radius: 8px; */
  /* background: #fff; */
}

.footerLeft p {
  width: 227px;
  height: auto;
  color: #fff;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
}

.footerItems {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 230px;
}

.footerItems h3 {
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 10px;
}

.footerItems p {
  color: #fff;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  cursor: pointer;
}

.additionalClass{}

.scrollBarItem{
  margin-bottom: 10px !important;
}



.footerRight {
  display: flex;
  width: 70%;
  justify-content: space-between;
}

.footerBottom {
  width: 100%;
  padding-top: 18px;
  padding-bottom: 17px;
  padding-left: 40px;
  padding-right: 40px;
  background: #444444;
  justify-content: center;
  align-items: center;
  display: flex;
  color: white;
  font-size: 14px;
  font-family: inter;
  font-weight: 500;
  line-height: 26px;
  text-align: center;
}

@media(max-width: 1100px){
  .footerContent{
    flex-direction: column;
  }

  .footer{
    height: auto;
    padding: 50px 0px;
  }

  .footerRight{
    width: 100%;
  }
}

@media(max-width: 767px) {

  .footerBottom {
    font-size: 12px;
  }

  .footerItems h3 {
    font-size: 14px;
  }

  .footerItems p {
    font-size: 12px;
  }

  .footerContent {
    flex-direction: column;
  }

  .footer {
    height: 375px;
  }

  .footerContent {
    gap: 40px;
  }

  .footerLeft {
    width: 100%;
  }

  .footerLeft p {
    width: 100%;
  }

  .footerRight {
    width: 100%;
  }
}

@media(max-width: 650px){
  .footerRight{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 40px 10px;
  }

  .footer{
    height: auto;
  }
}

@media (max-width: 480px) {
  .footerContent {
    width: 74%;
  }

  .footerItems{
    width: auto;
  }
}

@media (max-width: 450px) {
  .footerRight {
    flex-wrap: wrap;
    gap: 50px;
  }

  .footerItems {
    margin-right: 25px;
  }

  .footer {
    height: auto;
  }
}

/* @media (min-width: 868px) {
  .footerContent {
    width: 80%;
    gap: 10.375rem;
  }

  .footerBottom {
    font-size: 14px;
    padding-left: 100px;
    padding-right: 100px;
  }
}

@media (min-width: 1024px) {
  .footerBottom {
    font-size: 16px;
  }
} */