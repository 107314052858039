.calendarComponent {
    width: 100%;
}

.intervalTileWrapper {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin: 40px 0 8px 0;
}

.intervalTile {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #FF6666;
    width: auto;
    padding: 5px 10px;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 500;
    font-size: 0.875rem;
}

.themeWhite {
    background-color: #FF6666;
    color: white;
}

.themePink {
    color: #000 !important;
    background-color: white;
}

@media (min-width: 767px) {
    .intervalTile {
        font-size: 1rem;
    }
}