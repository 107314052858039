.navbarList {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.navbarList .pages {
  display: flex;
  gap: 2rem;
}

.navbarList .links {
  display: flex;
  flex-direction: column;
  column-gap: 1.5rem;
}

.navbarList .links .navIcon {
  margin-top: 2px;
}

.navbarList .links .iconLinks {
  display: flex;
}

.navbarList .links .iconLinks .ant-select-selector {
  border: none;
}

.navbarList .links .iconLinks img {
  height: 19px;
}

.navbarList .links .select {
  width: 8.4vw;
  height: 20px;
  border: none;
  font-size: 16px;
  background-color: none;
}

.navbarList .searchOptions {
  display: flex;
  gap: 1rem;
  margin-left: auto;
}

@media (min-width: 1020px) {
  .navbarList .links {
    flex-direction: row;
    row-gap: 1.5rem;
  }
}