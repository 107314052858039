.header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.625rem;
  padding: 0 0.75rem;
  /* border-bottom: 1px solid #dee2e6; */
}

.header h4 {
  font-size: 12px;
  margin: 0 auto;
  font-weight: 700;
}

.header button {
  border: none;
  background: transparent;
  font-size: 1.5rem;
  font-weight: 500;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  margin: auto 0;
  height: 28px;
}

.header button:hover {
  opacity: 0.75;
}

.backButton {
  margin-left: 10px;
}

@media (max-width: 420px) {
  .header .Button {
    height: 28px;
    width: 28px;
  }
}



@media (min-width: 868px) {
  .header h4 {
    font-size: 1.125rem;
  }
}