.navbarItem {
  /* display: inline-block; */
  white-space: nowrap;
  display: flex;
  /* justify-content: center; */
}

.navbarItem a {
  color: #0d0a19;
  /* to make icons along with text align horizontally */
  display: flex;
  /* flex-direction: row; */
  /* justify-content: flex-start; */
  gap: 0.625rem;
}

.navbarItem a:hover {
  /* color: #20183d; */
  text-decoration: none;
}

.navbarItem a.active {
  /* text-decoration: underline;
  text-underline-offset: 0.5rem; */
  font-weight: 900;
  color: black;
}

@media (min-width:1024px) {
  .navbarItem {
    justify-content: center;
  }
}