.modalWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modalWrapper img {
  width: 36px;
  height: 56px;
  object-fit: cover;
  margin-bottom: 30px;
}

.modalWrapper h1 {
  color: #f66;
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  margin-bottom: 28px;
}

.modalWrapper h2 {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  width: 283px;
  height: 43px;
  margin-bottom: 24px;
}

.buttonGroup {
  display: flex;
  gap: 12px;
}

.confirm {
  border-radius: 8px;
  background: #f66;
  display: flex;
  width: 120px;
  height: 48px;
  padding: 12px 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: none;
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.noConfirm {
  display: flex;
  width: 120px;
  padding: 12px 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 2px solid #f66;
  color: #f66;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  background: white;
}

.changeDate{
  width: 100%;
  background: #f66;
  border: none;
  color: white;
  font-size: 16px;
  height: 50px;
  font-weight: 500;
  margin-top: 40px;
}

@media (max-width: 430px){
    .buttonGroup{
        width: 90vw;
        justify-content: center;
        padding: 0 30px;
    }

    .buttonGroup button{
        width: 50%;
        font-size: 14px;
    }

    .modalWrapper h2 {
        width: 70vw;
    }

    .modalWrapper h1 {
        font-size: 24px;
        margin-bottom: 18px;
    }

    .modalWrapper img{
        margin-bottom: 20px;
    }
}
