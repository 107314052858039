.container {
    width: 88.3%;
    margin: 20px auto 20px auto;
}

.wrapper {
    display: flex;
}

.wrapper .venueInfo {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.priceDetails {
    width: 100%;
}

.description {
    padding: 20px;
    border-radius: 16px;
    border: 1px solid #E8E8E8;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.description .heading {
    color: #000;
    font-size: 1rem;
    font-weight: 500;
    line-height: normal;
}

.description .paragraph {
    color: #5D5C5C;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: normal;
}

.packageCards {
    padding: 24px;
    width: 308px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    border-radius: 16px;
    border: 1px solid #E8E8E8;
}

.packageCards .heading {
    color: #000;
    font-size: 1rem;
    font-weight: 500;
    line-height: normal;
}

.cardGrid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
}

.eventCardWrapper {
    width: 100%;
}

.tagWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;
}

.tag {
    min-height: 45px;
    padding: 12px 20px;
    border-radius: 8px;
    background: #F66;
    color: #FFF;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: normal;
}

.slider {
    width: 100% !important;
}

.packageContainer {
    width: 100% !important;
    margin-top: 60px !important;
    margin-bottom: 60px !important;
}

@media (max-width: 350px) {
    .packageCards {
        padding: 24px;
        width: 280px;
    }
}

@media (min-width: 767px) {
    .container {
        width: 89.5%;
    }

    .description {
        padding: 24px;
        row-gap: 21px;
    }

    .description .heading {
        font-size: 1.125rem;
    }

    .description .paragraph {
        font-size: 1rem;
    }

    .packageCards {
        row-gap: 24px;
        width: 631px;
    }

    .packageCards .heading {
        font-size: 1.125rem;
    }

    .cardGrid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }

    .tag {
        height: 48px;
        font-size: 1rem;
    }
}

@media (min-width: 1024px) {
    .container {
        width: 88%;
        margin: 28px auto 20px auto;
    }

    .wrapper {
        gap: 20px;
    }

    .wrapper .venueInfo {
        width: 61.4%;
    }

    .priceDetails {
        width: 36.4%;
    }

    .description .heading {
        font-size: 1.25rem;
    }

    .packageCards {
        row-gap: 28px;
    }

    .packageCards .heading {
        font-size: 1.25rem;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 88%;
    }

    .wrapper {
        gap: 40px;
    }

    .venueInfo {
        width: 62.5%;
    }

    .priceDetails {
        width: 34.3%;
    }
}

@media (min-width: 1920px) {
    .tag {
        line-height: 26px;
    }
}