.wishListContainer {
  width: 88%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 40px;
}

.titleSection h1 {
  color: #000;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 8px;
}

.titleSection p {
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 40px;
}

.headerSection {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

@media (min-width: 1024px) {
  .wishListContainer {
    margin-top: 40px;
    margin-bottom: 80px;
  }
}

@media (max-width: 1070px) {
  .titleSection h1 {
    font-size: 28px !important;
  }

  .titleSection p {
    font-size: 14px !important;
    margin-bottom: 18px;
  }

  .headerSection {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 40px;
  }
}

@media (max-width: 480px) {
  .titleSection h1 {
    font-size: 24px !important;
  }

  .headerSection {
    margin-bottom: 28px;
  }
}

@media (max-width: 900px) {
  .headerSection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 40px;
  }

  .titleSection p {
    margin-bottom: 18px;
  }

  .titleSection h1 {
    font-size: 28px;
  }

  .titleSection p {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .titleSection h1 {
    font-size: 24px;
  }
}

.wishListCards .cardsOuter {
  width: 100% !important;
}