.mainNavbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
  /* margin: auto; */
  width: 88vw;
  /* width: 90%; */
  font-family: 'Poppins';
}

.navbarGroup {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  gap: 1.75rem;
}

.navbarList {
  display: none;
  width: 100%;
  align-items: center;
  column-gap: 2rem;
}

.navbarList button {
  white-space: nowrap;
}

.humburgerButton {
  display: inline-block;
}

.searchIcon {
  width: 2.375rem;
  height: 2.375rem;
  cursor: pointer;
  margin-right: 5px;
}

.searchOpen {
  display: flex;
  gap: 1.188rem;
}

.searchOpen .searchButton {
  display: flex;
  gap: 0.5rem;
  border-radius: 32px;
}

.searchOpen img {
  cursor: pointer;
}

@media (min-width: 1020px) {
  .navbarList {
    display: flex;
  }

  .humburgerButton {
    display: none;
  }
}