.navbarList {
    width: 100%;
    z-index: 60;
    font-size: 1rem;
    font-weight: 400;
    display: flex;
    flex-direction: column !important;
    /* align-items: center; */
    /* justify-content: start; */
    padding: 1.5rem 1.75rem;
    overflow-y: scroll;
}

.closeIcon {
    width: 24px;
    height: 24px;
    margin-left: auto;
    cursor: pointer;
    margin-bottom: 1.5rem;
}

.navbarList .accountItem {
    width: 100%;
    margin-bottom: 2.75rem;
}

.navbarList .accountItem .navIcon {
    width: 1.188rem;
    margin-left: 0.75rem;
}

.navbarList .list {
    overflow-y: scroll;

}

.navbarList .list .iconLinks {
    width: 100%;
    padding: 1rem 0;
    border-bottom: 1px solid #EBEBEB;
    display: flex;
    z-index: 65;
}

.navbarList .links .iconLinks .navIcon {
    height: 19px;
    margin-left: 0.75rem !important;
}

.navbarList .links .iconLinks .ant-select-selector {
    border: none;
}



.navbarList .list .listItem {
    width: 100%;
    padding: 1rem 0;
    border-bottom: 1px solid #EBEBEB;
}

.navbarList .list .listItem .navIcon {
    width: 1.188rem;
    margin-left: 0.75rem;
}

.navbarList .line {
    width: 100%;
    height: 0.063rem;
    background: #EBEBEB;
    border: none;
}

.logoutWrapper {
    position: fixed;
    bottom: 0px;
    /* padding-bottom: 37px; */
    background-color: #FFF;
    left: 0;
    width: 62.7vw;
    /* padding: 0 2rem 1.75rem 2rem; */
    padding: 1.75rem;
}

.logoutWrapper .logout {
    width: 100%;
    height: 2.5rem;
    border-radius: 8px;
    border: 1px solid #D9D9D9;
    font-size: 1rem;
    font-weight: 400;

}