.modal {
  width: 88.33%;
  /* height: 100%; */
  /* top: 2.688rem; */
  font-family: 'Poppins';
  max-height: 685px !important;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.body {
  /* width: 28.125rem; */
  margin: auto;
  padding: 0 1.5rem 2.5rem 1.5rem;
  display: flex;
  row-gap: 1.75rem;
  flex-direction: column;
}

.lineBreak {
  text-align: center;
  position: relative;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.313rem;
  color: #9a9ea7;
  width: 100%;
  margin: 0;
}

.lineBreak::before {
  content: '';
  display: block;
  width: 45%;
  height: 1px;
  background: #ede8e9;
  position: absolute;
  top: 50%;
  left: 0;
}

.lineBreak::after {
  content: '';
  display: block;
  width: 45%;
  height: 1px;
  background: #ede8e9;
  position: absolute;
  top: 50%;
  right: 0;
}

.socialBtns {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.socialBtns button {
  width: 100%;
  height: 3.375rem;
  opacity: 0.5;
  font-size: 0.875rem;
  font-weight: 600;
}

.socialBtns button svg {
  float: left;
  margin-top: 0.313rem;
  width: 1rem;
  height: 1rem;
}

.signinFooter {
  display: flex;
  /* border-top: 1px solid #dee2e6; */
  /* height: 3.563rem; */
  /* font-size: 0.875rem; */
}

.signinFooter p {
  margin: 0 auto;
  color: #000;
  font-weight: 600;
  font-size: 14px !important;
}

.signUpLink {
  padding: 0px 10px;
  font-weight: bold;
  cursor: pointer;
  color: #FF6666;
  font-size: 14px !important;
}

@media (min-width: 480px) {
  .modal {
    width: 452px;
    height: auto;
    max-height: 591px;
  }

  .body {
    margin: 0;
    padding: 0 2.125rem 2.5rem 2.125rem;
  }
}

@media (min-width: 767px) {
  .modal {
    width: 452px;
    height: auto;
    max-height: 591px;
  }

  .body {
    margin: 0;
    padding: 0 3rem 2.5rem 3rem;
  }
}

@media (max-width: 390px) {
  .body {
    padding: 0 0.875rem 2.5rem 0.875rem;
  }
}









































/* .modal {
  width: 100%;
  height: 100%;
}

.signupMain {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
}

.signupBody {
  width: 28.125rem;
  height: auto;
  padding: 2rem;
  display: flex;
  row-gap: 1rem;
  flex-direction: column;
  margin: 0 auto;
}

.lineBreak {
  text-align: center;
  position: relative;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.313rem;
  color: #9a9ea7;
  width: 100%;
  margin: 0;
}

.lineBreak::before {
  content: '';
  display: block;
  width: 45%;
  height: 1px;
  background: #ede8e9;
  position: absolute;
  top: 50%;
  left: 0;
}

.lineBreak::after {
  content: '';
  display: block;
  width: 45%;
  height: 1px;
  background: #ede8e9;
  position: absolute;
  top: 50%;
  right: 0;
}

.socialBtns {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.socialBtns button {
  width: 100%;
  height: 3.375rem;
  opacity: 0.5;
  font-size: 0.875rem;
  font-weight: 600;
}

.socialBtns button svg {
  float: left;
  margin-top: 0.313rem;
  width: 1rem;
  height: 1rem;
}

.signupFooter {
  display: flex;
  border-top: 1px solid #dee2e6;
  height: 3.563rem;
  font-size: 0.875rem;
}

.signupFooter p {
  margin: auto;
}

.signInLink {
  padding: 0px 10px;
  font-weight: bold;
  cursor: pointer;
}

@media (min-width: 868px) {
  .modal {
    width: 28.125rem;
    height: auto;
  }

  .signupBody {
    margin: 0;
  }
} */