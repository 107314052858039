.eventsOuter {
  display: flex;
  flex-direction: column;
  height: 361px;
  width: 305px;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 16px;
}

.titleImage {
  width: 305px;
  height: 225px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  object-fit: cover;
}

.titleSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 8px;
  margin-left: 16px;
  align-items: flex-start;
}

.titleSection h2 {
  color: black;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.reviewSection {
  display: flex;
  gap: 4px;
  align-items: center;
  margin-right: 18px;
}

.reviewSection img {
  width: 14px;
  height: 14px;
}

.reviewSection h3 {
  color: black;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 300;
}

.locationSection {
  display: flex;
  gap: 4px;
  margin-left: 16px;
  margin-bottom: 8px;
}

.locationSection h3 {
  color: #6B6969;
  font-size: 14px;
  font-family: Poppins;
  font-weight: 400;
}

.priceSection {
  display: flex;
  gap: 10px;
  position: absolute;
  bottom: 16px;
  left: 16px;
  align-items: center;
}

.priceSection h2 {
  color: #FF6666;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 600;
}

.priceSection h3 {
  color: #B1AEAE;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 500;
  text-decoration: line-through;
}

.heartIcon {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}

.loadingIcon {
  position: absolute;
  top: 16px;
  right: 16px;
  color: #FF6666;
}

@media (max-width: 1024px) {
  .eventsOuter {
    height: 334px;
    width: 280px;
    object-fit: cover;
  }

  .titleImage {
    width: 280px;
    height: 206px;
    object-fit: cover;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .titleSection {
    margin-top: 12px;
    margin-bottom: 4px;
    margin-left: 16px;
  }


  .priceSection {
    display: flex;
    gap: 10px;
    position: absolute;
    bottom: 14px;
    left: 16px;
    align-items: center;
  }

  .priceSection h3 {
    color: #B1AEAE;
    font-size: 12px;
    font-family: Poppins;
    font-weight: 500;
    text-decoration: line-through;
  }

  .heartIcon {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }

  .loadingIcon {
    position: absolute;
    top: 16px;
    right: 16px;
    color: #FF6666;
  }

}

@media (max-width: 767px) {
  .eventsOuter {
    height: 310px;
    width: 260px;
    border-radius: 7px;
  }

  .titleImage {
    width: 260px;
    height: 191px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
  }

  .titleSection {
    margin-top: 10px;
    margin-bottom: 5px;
    margin-left: 14px;
  }

  .titleSection h2 {
    font-size: 14px;
  }

  .reviewSection {
    margin-right: 16px;
  }

  .reviewSection img {
    width: 11px;
    height: 11px;
  }

  .reviewSection h3 {
    font-size: 14px;
  }

  .locationSection {
    margin-left: 14px;
    margin-bottom: 8px;
  }

  .locationSection h3 {
    font-size: 12px;
  }

  .priceSection {
    bottom: 14px;
    left: 14px;
  }

  .priceSection h2 {

    font-size: 14px;

  }

  .priceSection h3 {
    font-size: 10px;

  }

  .heartIcon {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    width: 23px;
    height: 23px;
  }

  .loadingIcon {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 23px;
    height: 23px;
    color: #FF6666;
  }
}