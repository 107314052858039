.custom-pagination-wedding{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 50px;
}

.custom-pagination-wedding ul{
    display: flex;
    gap: 28px;
    justify-content: center;
    margin-top: 60px;
}

.prev-button{
    font-size: 16px;
    font-family: Poppins;
    font-weight: 500;
    color: #ff6666;
    margin-right: 18px;
    cursor: pointer;
}

.next-button{
    font-size: 16px;
    font-family: Poppins;
    font-weight: 500;
    color: #ff6666;
    margin-left: 18px;
    cursor: pointer;
}

@media(max-width: 360px){
    .prev-button{
        margin-right: 8px;
    }
    .next-button{
        margin-left: 8px;;
    }
}

.custom-pagination-wedding .pagination li.active a {
    background-color: #ff6666; /* Change this to your desired color */
    color: #fff; /* Change this to your desired text color */
    width: 30px;
    height: 44px;
    font-size: 16px;
    font-family: Poppins;
    font-weight: 500;
    padding: 10px;
    border-radius: 8px;
  }

  .custom-pagination-wedding .pagination li a {
    color: black;
    width: 30px;
    height: 44px;
    font-size: 16px;
    font-family: Poppins;
    font-weight: 500;
    padding: 10px;
  }

  .pagination .page-item:first-child .page-link,
.pagination .page-item:last-child .page-link {
  display: none !important;
}

.custom-pagination-wedding li:first-child {
    /* display: none; */
    font-size: 20px;
  }

  .custom-pagination-wedding li:last-child {
    /* display: none; */
    font-size: 20px;
  }

  .custom-pagination-wedding li:nth-child(2),
.custom-pagination-wedding li:nth-last-child(2) {
  display: none;
}