.priceContainer {
  width: 100%;
  border-radius: 16px;
  border: solid 1px #e8e8e8;
  padding: 20px 0 24px 0;
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
}

.sectionWrapper {
  padding: 0 24px 0 24px;
}

.heading {
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 24px;
}

.subHeading {
  font-size: 0.875rem;
  font-weight: 300;
  margin-bottom: 21px;
  color: #5d5c5c;
}

.listRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 6px;
}

.rowTitle {
  font-size: 0.875rem !important;
  font-weight: 400;
}

.checkboxClass {
  width: 16px !important;
  height: 16px !important;
  margin: auto 0;
}

.rowValueWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-align: right;
}

.rowValueWrapper .rowValue {
  font-size: 1rem;
  font-weight: 500;
  line-height: 30px;
  color: #ff6666;
}

.rowValueWrapper .rowStrikePrice {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 24px;
  text-decoration: line-through;
  color: #a2a2a2;
}

.breakLine {
  content: "";
  display: inline-block;
  width: 100%;
  height: 0.8px;
  background-color: #dedede;
  margin-top: 12px;
  margin-bottom: 20px;
}

.inputSection {
  display: flex;
  flex-direction: column;
  padding: 0 24px 0 24px;
}

.inputSection .inputLabel {
  font-size: 1rem;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 7px;
}

.cartButton {
  height: 50px !important;
  margin-top: 10px;
  font-size: 1.063rem;
}

.peopleInput {
  height: 50px;
  font-size: 16px;
  border: 1px solid #ff6666;
}

.profileForm {
  width: 100%;
  margin-top: 25px;
}

.profileForm input {
  height: 50px;
  padding: 15px;
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.profileForm textarea {
  padding: 15px;
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.profileForm h4 {
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 7px;
}

.modalContainer {
  height: 580px;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.modalPoint {
  font-size: 14px;
  font-weight: 600;
  margin-top: 15px;
}

.modalPoint span {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  background-color: #ff6666;
  border-radius: 8px;
  padding: 5px 10px;
}

@media (max-width: 330px) {
  .profileForm h4 {
    font-size: 14px;
  }

  .profileForm input {
    height: 40px;
  }
}

@media (min-width: 500px) {
  .peopleInput {
    font-size: 1.063rem;
  }
}

@media (min-width: 767px) {
  .heading {
    font-size: 1.125rem;
  }

  .rowTitle {
    font-size: 1rem !important;
  }

  .rowValueWrapper .rowValue {
    font-size: 1.25rem;
  }

  .rowValueWrapper .rowStrikePrice {
    font-size: 1rem;
  }
}

@media (min-width: 1024px) {
  .heading {
    font-size: 1rem;
  }

  .rowTitle {
    font-size: 1rem !important;
  }

  .rowValueWrapper .rowValue {
    font-size: 1.25rem;
    line-height: 30px;
  }

  .rowValueWrapper .rowStrikePrice {
    font-size: 1rem;
    line-height: 24px;
  }

  .modalPoint {
    font-size: 14px;
    font-weight: 600;
  }

  .listRow {
    margin-top: 20px;
  }

  .warnColor {
    color: #faad14;
  }
}
