.modal {
  position: fixed;
  /* top: 5.5rem; */
  /* top: 4rem; */
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  /* transform: translateX(-50%); */
  max-width: 331.25rem;
  background: #ffffff;
  box-shadow: 0px -6px 20px rgb(13 10 25 / 10%);
  /* border-radius: calc(0.3rem - 1px); */
  border-radius: 16px;
  z-index: 60;
  margin: 0;
}