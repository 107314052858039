.container {
    width: 88.3%;
    margin: 20px auto 20px auto;
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.emptyCart {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 1rem;
}

.titleSectionWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.clearButton {
    height: fit-content;
    margin-left: auto;
}

.titleSection {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

.title {
    color: #000;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: normal;
}

.desc {
    color: #000;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: normal;
}

.cartWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.cartItemsList {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.cartItem {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    padding: 16px;
    border-radius: 16px;
    box-shadow: -1px -1px 2px 0px rgba(0, 0, 0, 0.08), 1px 1px 2px 0px rgba(0, 0, 0, 0.08);
}

.cartItemImageContainer {
    position: relative;
}

.tag {
    position: absolute;
    top: 8px;
    left: 8px;
    padding: 4px 10px;
    font-size: 0.875rem;
    border-radius: 4px;
    background: #F66;
    height: 29px;
    font-weight: 500;
    color: #FFF;
}

.cartItemImage {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 4px;
    max-height: 209px;
}

.details {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.itemName {
    color: #000;
    font-size: 1rem;
    font-weight: 600;
    line-height: normal;
}

.cityWrap {
    margin-top: 10px;
    display: flex;
    align-items: center;
    column-gap: 4px;
}

.cityIcon {
    width: 15px;
    height: 15px;
    color: #5D5C5C;
}

.city {
    color: #5D5C5C;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: normal;
}

.breakLine {
    content: "";
    display: inline-block;
    width: 100%;
    height: 0.8px;
    background-color: #DEDEDE;
    margin-top: 12px;
    margin-bottom: 16px;
}

.eventDate {
    color: #000;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: normal;
    margin-left: 17px;
    position: relative;
}

.peopleNumber {
    color: #000;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: normal;
    margin-left: 17px;
    position: relative;
    margin-top: 12px;
}

.eventDate::before {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    background-color: #f66;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -17px;
}

.peopleNumber::before {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    background-color: #f66;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -17px;
}

.eventDate2 {
    color: #000;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: normal;
    margin-left: 17px;
    position: relative;
    margin-top: 12px;
}

.eventDate2::before {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    background-color: #f66;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -17px;
}

.addonsData {
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    padding: 10px 12px;
    background: #F1F9FE;
    margin-top: 16px;
    margin-left: 17px;
    position: relative;
}

.addonsData::before {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    background-color: #f66;
    border-radius: 50%;
    position: absolute;
    top: 20px;
    transform: translateY(-50%);
    left: -17px;
}

.addOnTitle {
    color: #000;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: normal;
}

.addOnItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.addOnItemName {
    color: #000;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: normal;
}

.addOnItemprice {
    color: #F66;
}

.removeWrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
    cursor: pointer;
}

.removeWord {
    color: #7F7F7F;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: normal;
}

.bottomSection {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 20px;
}

.totalAmount {
    color: #000;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: normal;
    position: relative;
    margin-left: 17px;
    margin-right: 20px;
}

.totalAmount::before {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    background-color: #f66;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -17px;
}

.amountTag {
    display: inline-flex;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: #F66;
    color: #FFF;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: normal;
}

.removeButton {
    color: #F66;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    margin-left: auto;
    display: flex;
    align-items: center;
}

.priceDetails {
    width: 100%;
}

@media (min-width: 767px) {
    .container {
        row-gap: 40px;
    }

    .titleSectionWrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .title {
        font-size: 1.75rem;
    }

    .cartItem {
        display: flex;
        flex-direction: row;
        column-gap: 24px;
    }

    .tag {
        font-size: 1rem;
        height: 32px;
    }

    .cartItemImage {
        width: 284px;
        border-radius: 4px;
    }

    .itemName {
        font-size: 1.125rem;
    }

    .city {
        font-size: 1rem;
    }

    .breakLine {
        margin-top: 12px;
        margin-bottom: 14px;
    }

    .eventDate {
        font-size: 1rem;
        /* margin-bottom: 14px; */
    }

    .peopleNumber {
        font-size: 1rem;
        /* margin-bottom: 14px; */
    }

    .eventDate2 {
        font-size: 1rem;
        /* margin-bottom: 14px; */
    }

    .addonsData {
        margin-top: 14px;
    }

    .addOnTitle {
        font-size: 1rem;
    }

    .addOnItemName {
        font-size: 1rem;
    }

    .totalAmount {
        font-size: 1rem;
    }

    .amountTag {
        font-size: 1rem;
        padding: 4px 16px;
    }

    .removeButton {
        font-size: 1rem;
    }

}

@media (min-width: 1024px) {
    .container {
        width: 88.3%;
        margin: 40px auto 20px auto;
    }

    .title {
        font-size: 2rem;
    }

    .desc {
        font-size: 1rem;
    }

    .cartWrapper {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
    }

    .cartItemsList {
        width: 66.6%;
    }

    .cartItem {
        display: flex;
        flex-direction: row;
        column-gap: 20px;
    }

    .tag {
        top: 6px;
        left: 6px;
    }

    .cartItemImage {
        width: 241px;
        border-radius: 4px;
    }

    .itemName {
        font-size: 1.25rem;
    }

    .city {
        font-size: 1rem;
    }

    .breakLine {
        margin-top: 12px;
        margin-bottom: 12px;
    }

    .eventDate {
        font-size: 1rem;
    }

    .peopleNumber{
        font-size: 1rem;
    }

    .eventDate2 {
        font-size: 1rem;
    }

    .addonsData {
        margin-top: 14px;
    }

    .priceDetails {
        width: 32.2%;
    }

    .amountTag {
        font-size: 1rem;
        padding: 4px 12px;
    }
}

@media (min-width: 1200px) {
    .cartWrapper {
        display: flex;
        flex-direction: row;
        column-gap: 40px;
    }

    .cartItem {
        display: flex;
        flex-direction: row;
        column-gap: 24px;
        padding: 16px 45px 16px 16px;
    }

    .cartItemImage {
        width: 284px;
        border-radius: 4px;
    }

    .breakLine {
        margin-top: 16px;
        margin-bottom: 16px;
    }

    .amountTag {
        font-size: 1rem;
        padding: 4px 16px;
    }
}