.dropdown {
    top: 80px;
    position: absolute;
    /* position: fixed; */
    width: 14.625rem;
    height: 10.25rem;
    margin-left: -40px;
    z-index: 70;
    border-radius: 8px;
    background: #FFF;
    padding: 1rem;
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.08), -1px -1px 2px 0px rgba(0, 0, 0, 0.08);
}

.list {
    display: flex;
    flex-direction: column;
    /* z-index: 70; */
}

.list .line {
    width: 203px;
    height: 1px;
    background: #F1EEEE;
    border: none;
    margin-top: 0.625rem;
    margin-bottom: 0.875rem;
}

.list .logout {
    width: 100%;
    border-radius: 8px;
    border: 1px solid #D9D9D9;
    margin-top: 1.25rem;
    font-size: 1rem;
    font-weight: 400;
}