.timingsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 28px;
    padding: 20px;
    border-radius: 16px;
    border: 1px solid #E8E8E8;
    font-family: 'Poppins', sans-serif;
}

.title {
    color: #000;
    font-size: 1rem;
    font-weight: 500;
    line-height: normal;
}

.sectionWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

/* .timeWrapper {
    width: 100%;
} */

.tagWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;
}

.tag {
    height: 45px;
    padding: 12px 20px;
    border-radius: 8px;
    background: #F66;
    color: #FFF;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: normal;
}

@media (min-width: 767px) {
    .timingsContainer {
        row-gap: 40px;
        padding: 24px;
    }

    .title {
        font-size: 1.125rem;
    }

    .tag {
        height: 48px;
        font-size: 1rem;
    }
}

@media (min-width: 1024px) {
    .title {
        font-size: 1.25rem;
    }

    .sectionWrapper {
        row-gap: 24px;
    }
}

@media (min-width: 1200px) {
    .timingsContainer {
        flex-direction: row;
    }

    .sectionWrapper {
        width: 50%;
    }

    .verticalLine {
        content: "";
        height: 100%;
        width: 1px;
        background-color: #E8E8E8;
        margin: 0 24px;
    }

    /* .timeWrapper {
        width: 50%;
    } */
}

@media (min-width: 1920px) {
    .tag {
        line-height: 26px;
    }
}