html,
body,
#root {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Poppins', 'Roboto', sans-serif;
  color: #0d0a19;
  list-style: none;
  background-color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

button {
  cursor: pointer;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  font-size: 16px;
}

@media (min-width: 640px) {
  :root {
    font-size: 16px;
  }
}

@media (min-width: 868px) {
  :root {
    font-size: 16px;
  }
}

/* @media (min-width: 1024px) {
  :root {
    font-size: 20px;
  }
} */

/* @media (min-width: 1440px) {
  :root {
    font-size: 16px;
  }
} */

/* @media (min-width: 1920px) {
  :root {
    font-size: 20px;
  }
}

@media (min-width: 2560px) {
  :root {
    font-size: 30px;
  }
} */

/* react-image-gallery - making thumbnail image responsive */
.image-fit img {
  object-fit: scale-down !important;
}

/* primary color - pink - #FF6666 */
/* primary grat color - #D9D9D9 */
.navbar-select {
  width: 85%;
}

.navbar-select .ant-select-selector {
  border: none !important;
  background: transparent !important;
  box-shadow: none !important;
  font-size: 16px !important;
  font-family: Poppins !important;
}

.navbar-select .ant-select-selector .ant-select-selection-item {
  margin-top: 5px;
}


@media (min-width: 1024px) {
  .navbar-select {
    width: 8.4vw;
  }
}

@media (min-width: 1920px) {
  .navbar-select .ant-select-selector {
    font-size: 18px !important;
  }
}

.slick-slide>div {
  margin: 0 10px;
}

.categories-slider .slick-initialized .slick-slide {
  display: flex !important;
  justify-content: center !important;
}

.categories-slider .slick-slider .slick-list {
  height: 265px !important;
}

@media (max-width: 1020px) {
  .categories-slider .slick-slider .slick-list {
    height: 232px !important;
  }
}

@media (max-width: 767px) {
  .categories-slider .slick-slider .slick-list {
    height: 175px !important;
  }
}

@media (max-width: 480px) {
  .categories-slider .slick-slider .slick-list {
    height: 165px !important;
  }
}

.package-slider .slick-slider .slick-list {
  height: 295px !important;
}

@media (max-width: 1020px) {
  .package-slider .slick-slider .slick-list {
    height: 265px !important;
  }
}

@media (max-width: 767px) {
  .package-slider .slick-slider .slick-list {
    height: 255px !important;
  }
}

.categories-slider .slick-slide {
  outline: none !important;
  /* Remove the default outline style */
}

/* Optionally, you can add a border to mimic the outline effect if needed */
.categories-slider .slick-slide:focus {
  border: none !important;
  /* Customize the border color and width as needed */
}


.package-slider .slick-initialized .slick-slide {
  display: flex;
  justify-content: flex-start;
  /* transform: translateX(-240px) */
  /* width: 700px !important; */
}

.package-slider .slick-slider .slick-track, .package-slider .slick-slider .slick-list {
  height: 287px;

}

/* @media (max-width: 1600px){
  .package-slider .slick-initialized .slick-slide{
  transform: translateX(-16px)
  }
} */

.event-slider .slick-slider .slick-track {
  height: 361px !important;
}

.default-slide .slick-slider .slick-track,.default-slide .slick-slider .slick-list{
  height: 361px !important;
}

@media(max-width: 767px) {
  .event-slider .slick-slider .slick-track,.event-slider .slick-slider .slick-list {
    height: 310px !important;
  }
}

.input-flex .ant-input-number-outlined {
  margin-right: 0 !important;
  margin-left: 0 !important;
  height: 43px !important;

}

.input-flex .ant-input-number-input {
  height: 43px !important;

}

.ant-slider .ant-slider-track {
  background-color: #FF6666 !important;
}

.filterOuter {
  width: 22%;
  border: 2px #eeeeee solid;
  border-radius: 16px;
  height: 1280px;
  padding-left: 17px;
  padding-top: 38px;
  padding-right: 17px;
  padding-bottom: 38px;
}

@media (max-width: 1200px) {

  .filterOuter {
    width: 32%;
  }
}

@media (max-width: 975px) {

  .filterOuter {

    transition: transform 0.5s ease;
    /* Use transform for smooth sliding effect */
    white-space: nowrap;
    transform: translateX(-150%);
    position: absolute;
    background-color: white;
    z-index: 9999;
    width: 400px;
    top: 10px;
    left: 0;
    height: auto;

  }

  .filterOuterSlider {
    transition: transform 0.5s ease;
    transform: translateX(0);
    position: absolute;
    z-index: 9999;
    background-color: white;
    padding: 20px;
    border-radius: 16px;
    width: 400px;
    top: 10px;
    left: 0;
    height: auto;
  }
}

@media (max-width: 480px) {
  .filterOuter {
    width: 310px;
  }

  .filterOuterSlider {
    width: 310px;
  }
}

@media (max-width: 325px) {
  .filterOuter {
    width: 90vw;
  }

  .filterOuterSlider {
    width: 90vw;
  }
}



.filterOuter {
  width: 22%;
  border: 2px #eeeeee solid;
  border-radius: 16px;
  /* height: 950px; */
  height: fit-content;
  padding-left: 17px;
  padding-top: 38px;
  padding-right: 17px;
  padding-bottom: 38px;
}

.filterOuterWithFilter {
  width: 22%;
  border: 2px #eeeeee solid;
  border-radius: 16px;
  height: fit-content;
  padding-left: 17px;
  padding-top: 38px;
  padding-right: 17px;
  padding-bottom: 38px;
}

@media (max-width: 1200px) {

  .filterOuter {
    width: 32%;
  }
}

@media (max-width: 975px) {

  .filterOuter {

    transition: transform 0.5s ease;
    /* Use transform for smooth sliding effect */
    white-space: nowrap;
    transform: translateX(-150%);
    position: absolute;
    background-color: white;
    z-index: 9999;
    width: 400px;
    top: 10px;
    left: 0;
    height: auto;

  }

  .filterOuterSlider {
    transition: transform 0.5s ease;
    transform: translateX(0);
    position: fixed;
    z-index: 9999;
    background-color: white;
    padding: 20px;
    border-radius: 16px;
    width: 400px;
    top: 10px;
    left: 0;
    height: 100vh;
    overflow-y: auto;
  }
}

@media (max-width: 480px) {
  .filterOuter {
    width: 310px;
  }

  .filterOuterSlider {
    width: 310px;
  }
}

@media (max-width: 325px) {
  .filterOuter {
    width: 90vw;
  }

  .filterOuterSlider {
    width: 90vw;
  }
}





.signInInput {
  height: 50px;
  color: #000 !important;
}

.signInInput ::placeholder {
  color: #000 !important;
}

.signInInput2 {
  height: 50px;
  color: #000 !important;
}

@media (max-width: 390px) {
  .signInInput {
    height: 40px;
  }

}

.slider-style .slick-slide>div {
  margin: 0 !important;
}

.slider-style .slick-slider .slick-track {
  height: 320px !important;
  border-radius: 8px !important;
}




  .slider-style .slick-slider .slick-track {
    height: auto !important;
    border-radius: 12px !important;
  }


@media (min-width: 1024px) {
  .slider-style .slick-slider .slick-track {
    width: 550px;
    aspect-ratio: 800/440;
    border-radius: 12px !important;
  }
}


@media (min-width: 1400px) {
  .slider-style .slick-slider .slick-track {
    width: 800px;
    aspect-ratio: 800/440 !important;
    border-radius: 16px !important;
  }
}

@media (min-width: 1700px) {
  .slider-style .slick-slider .slick-track {
    width: 1000px;
    aspect-ratio: 800/440 !important;
    border-radius: 16px !important;
  }
}

/* Datepicker design */
.dateInput {
  height: 50px;
  border-color: #A1A1A1 !important;
  /* color: #A1A1A1 !important; */
}

.dateInput .ant-picker-input {
  color: #A1A1A1 !important;
}

/* Venue detail page - gallery tabs */
.venueTabs .ant-tabs-nav {
  /* tabs bar */
  padding: 0 20px 0 20px !important;
}

.venueTabs .ant-tabs-tabpane {
  padding: 0 20px 24px 20px !important;
}

.venueTabs .ant-tabs-nav-list .ant-tabs-ink-bar-animated {
  height: 6px !important;
  border-radius: 11px !important;
}

.venueTabs .ant-tabs-nav-list .ant-tabs-tab-btn {
  font-size: 1rem !important;
  font-weight: 600 !important;
}

@media (min-width: 767px) {
  .venueTabs .ant-tabs-nav {
    /* tabs bar */
    padding: 8px 28px 0 28px !important;
  }

  .venueTabs .ant-tabs-tabpane {
    padding: 0 28px 24px 28px !important;
  }

  .venueTabs .ant-tabs-nav-list .ant-tabs-tab-btn {
    font-size: 1.125rem !important;
    font-weight: 600 !important;
  }
}

@media (min-width: 1024px) {
  .venueTabs .ant-tabs-nav {
    padding: 8px 24px 0 24px !important;
  }

  .venueTabs .ant-tabs-tabpane {
    padding: 0 24px 24px 24px !important;
  }

  .venueTabs .ant-tabs-nav-list .ant-tabs-ink-bar-animated {
    height: 6px !important;
    border-radius: 11px !important;
  }

  .venueTabs .ant-tabs-nav-list .ant-tabs-tab-btn {
    font-size: 1.25rem !important;
    font-weight: 500 !important;
  }
}

.venueImage {
  border-radius: 8px !important;
}

.venueImage .ant-image .ant-image-mask {
  border-radius: 8px !important;
}

.ant-slider-horizontal .ant-slider-rail {
  background-color: #DEDEDE !important;
}

.ant-modal {
  top: 50% !important;
  transform: translateY(-50%) !important;
}

.phone-ant-input {
  display: flex;
  align-items: center;
}

.phone-ant-input .PhoneInputInput {
  flex: 1;
  box-sizing: border-box;
  margin: 0px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  height: 50px;
  width: 100%;
  min-width: 0px;
  padding: 8.5px 11px;
  color: rgb(69, 85, 96);
  font-size: 14px;
  line-height: 1.5;
  background-color: rgb(255, 255, 255);
  background-image: none;
  border: 1px solid #d9d9d9;
  /* border-color: #d9d9d9; */
  border-radius: 6px;
  transition: all 0.3s ease 0s;
}

.custom-autocomplete input.ant-input:focus {
  border-color: red !important;
  /* Set the desired border color when focused */
  box-shadow: none;
  /* Remove any focus box-shadow if present */
}

.react-google-autocomplete input {
  outline: none !important;
  border: 1px solid #fff !important;
  font-family: 'Poppins', 'Roboto', sans-serif;
}

.react-google-autocomplete input:focus {
  outline: none !important;
  border: 1px solid #fff !important;
}

.pac-container {
  font-family: 'Poppins', 'Roboto', sans-serif;
}

.pac-container:after {
  display: none !important;
  /* to remove the google logo in dropdown menu */
}

.gm-style input {
  font-family: 'Poppins', 'Roboto', sans-serif;
  /* Replace 'Your Desired Font' with your preferred font name */
}

.pac-target-input {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.pac-target-input:focus {
  outline: none;
}

/* venue detail image correction */
.ant-image {
  height: 100% !important;
}

.ant-image-img {
  height: 100% !important;
}

/* -------react calender-------- */
.react-calendar {
  width: 100%;
  max-width: 100%;
  background: white;
  border: 1px solid #FF6666;
  /* border: 1px solid #a0a096; */
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  border-radius: 8px;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer>* {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
  background-color: #FF6666;
  border-radius: 8px 8px 0 0;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  color: #fff;
  font-weight: 700;
}

.react-calendar__navigation button:disabled {
  background-color: #cc5151;
  /* background-color: #f0f0f0; */
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #ffa3a3;
  /* background-color: #e6e6e6; */
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font: inherit;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font: inherit;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__decade-view__years__year--neighboringDecade,
.react-calendar__century-view__decades__decade--neighboringCentury {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
  font: inherit;
  font-size: 0.833em;
  border-radius: 8px;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
  color: #ababab;
}

.react-calendar__month-view__days__day--neighboringMonth:disabled,
.react-calendar__decade-view__years__year--neighboringDecade:disabled,
.react-calendar__century-view__decades__decade--neighboringCentury:disabled {
  color: #cdcdcd;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #ffe0e0;
  /* background-color: #e6e6e6; */
}

.react-calendar__tile--now {
  background: #ffff76;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background: #ff6666;
  /* background: #006edc; */
  color: white;
}

.react-calendar__tile--active p {
  color: white !important;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #ff6666;
  /* background: #1087ff; */
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

/* -------react calender end-------- */

.fc-h-event .fc-event-title-container {
  cursor: pointer;
  background: red;
  font-weight: 500;
}

.fc-theme-standard td {
  height: 150px;
}

.fc-theme-standard th {}


.venue-modal .ant-modal-title {
  font-size: 18px;
  font-weight: 700;
}

/* .ant-message {
  font-size: 16px !important;
} */

/* Override Ant Design's message box styles for success messages */
.ant-message-notice-content .ant-message-success {
  background: green !important;
  color: #fff;
}

.ant-message-notice-success .ant-message-notice-content {
  background: green !important;
}

/* Override Ant Design's message box styles for error messages */
.ant-message-notice-content .ant-message-error {
  background: red !important;
  color: #fff;
}

.ant-message-notice-error .ant-message-notice-content {
  background: red !important;
}

/* Hide the icons coming before messages */
.ant-message-notice-content .ant-message-error span svg {
  display: none !important;
}

.ant-message-notice-content .ant-message-success span svg {
  display: none !important;
}

/* Carousel for banner in home page */
@media (max-width:767px) {
  .ant-carousel .slick-slider .slick-list {
    height: auto !important;
  }
}

/* to remove black dot coming in carousel along with white bar */
.bannerCarousel .slick-dots li.slick-active button:before {
  color: transparent !important;
}

.bannerCarousel .slick-dots li button:before {
  color: transparent !important;
}

/*error h4 in home page*/
.home-error {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 30px;
}

.banner-location {
  position: relative;
  width: 100%;
  height: 46px;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  padding: 0 19px;
  color: black;
  font-size: 14px;
  font-family: 'Roboto';
  font-weight: 400;
}

.banner-location:focus {
  border-color: #FF6666 !important;
}

.banner-location::placeholder {
  color: black;
  font-size: 14px;
  font-family: 'Roboto';
  font-weight: 400;
}

.autocomplete-div {
  position: relative;
  width: 100%;
}

.autocomplete-div .dropdown-arrow {
  position: absolute;
  right: 19px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: black;
  font-size: 12px;
}

.privacy-content ul,.privacy-content ol{
  list-style-position: inside !important;
  list-style-type: disc !important;
}