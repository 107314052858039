.galleryContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    font-family: 'Poppins', sans-serif;
}

.galleryGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
}

.image {
    border-radius: 8px;
}

.image>.ant-image-mask {
    border-radius: 8px;
}

.viewAll {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 4px;
    font-size: 1rem;
    font-weight: 500;
    line-height: normal;
    color: #F66;
    margin-left: auto;
    cursor: pointer;
}

.venueImage {
    width: 100%;
    /* min-height: 186px; */
    height: 100%;
    border-radius: 8px;
    background-color: #D9D9D9;
    position: relative;
    cursor: pointer;
    object-fit: fill;
}

.playButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
}

.thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.videoModal {
    width: 95vw !important;
}

.modalBody {
    height: 30vh !important;
}

@media (min-width: 767px) {
    .galleryGrid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 12px;
    }

    .videoModal {
        width: 95vw !important;
    }

    .modalBody {
        height: 50vh !important;
    }
}

@media (min-width: 1024px) {
    .galleryGrid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 12px;
    }

    .videoModal {
        width: 60vw !important;
    }

    .modalBody {
        height: 60vh !important;
    }
}

@media (min-width: 1200px) {
    .galleryGrid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 12px;
    }
}