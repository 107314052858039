.cardWrapper {
  width: 100%;
  height: auto;
  border-radius: 16px;
  display: flex;
  gap: 20px;
}

.outerWrapper {
  width: 100%;
  height: auto;
  border-radius: 16px;
  background: #fff;
  box-shadow: -1px -1px 2px 0px rgba(0, 0, 0, 0.08),
    1px 1px 2px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  gap: 20px;
  flex-direction: column;
  padding: 16px;
}

.cardWrapper img {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  height: 100%;
  width: 413px;
  object-fit: cover;
}

.detailsContainer {
  margin-top: 8px;
  margin-right: 20px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.timeSection {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  align-items: center;
  border-bottom: 1px solid #dedede;
  padding-bottom: 20px;
  row-gap: 10px;
}

/* .timeSection .exactTime::before{
  content: '';
  display: block;
  width: 6px;
  height: 6px;
  background-color: #f66;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -17px;
} */

.timeSection h2 {
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  /* width: 400px; */
  width: 50%;
}

.titleDate {
  color: #f66;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
}

.timeSection h3 {
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  /* width: 300px; */
  position: relative;
}

.exactTime {
  font-weight: 400;
}

.upcomingText {
  width: 20%;
}

.timeSection p {
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  width: 126px;
  height: 32px;
  border-radius: 4px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.timeAdjust {
  display: flex;
  align-items: center;
  width: 30%;
}

.redDot {
  width: 6px;
  height: 6px;
  display: block;
  border-radius: 50%;
  background-color: #f66;
  margin-right: 11px;
}

.bookingNumber p {
  color: #5d5c5c;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 13px;
}

.imageContainer{
  width: 450px;
  height: 360px;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
}

@media (max-width: 1400px) {
  .timeAdjust {
    width: 50%;
    justify-content: flex-end;
  }

  .timeSection h2 {
    width: 50%;
  }
}

.addressSection {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #dedede;
}

.leftDiv {
  display: flex;
  flex-direction: column;
}

.locationSection {
  display: flex;
  gap: 4px;
  align-items: center;
  margin-bottom: 10px;
}

.locationSection img {
  width: 15px !important;
  height: 15px !important;
  object-fit: contain !important;
}

.locationSection p {
  color: #5d5c5c;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.eventTitle {
  color: #000;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 10px;
}

.addressDetails {
  color: #5d5c5c;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  width: 405px;
  height: 48px;
  margin-bottom: 16px;
}

.capacityDiv {
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  display: inline-flex;
  padding: 6px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: rgba(235, 247, 254, 0.7);
}

.capacitySection {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  width: 450px;
  row-gap: 10px;
}

.rightDiv {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.rightDiv button {
  width: 283px;
  height: 41px;
  border-radius: 8px;
  border: 1px solid #a1a1a1;
  background: #fff;
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-right: 37px;
}

.paymentGreen {
  color: #31a83d;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.paymentRed {
  color: #ff772a;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.priceSection {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 80%;
  margin-left: 17px;
}

.priceSection h3 {
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  position: relative;
}

.priceSection h3::before {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  background-color: #f66;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -17px;
}

.exactTime {
  font-weight: 400;
}

.exactPrice {
  color: #f66;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.priceBottomSection {
  display: none;
}

@media (max-width: 1360px) {
  .addressSection {
    flex-direction: column;
    row-gap: 16px;
  }

  .priceSection {
    width: 100%;
  }
}

@media (max-width: 1260px) {
  .timeSection h2 {
    width: 60%;
  }

  .bookingNumber p {
    font-size: 14px;
  }
}

@media (max-width: 1260px) {
  .cardWrapper {
    align-items: flex-start;
    display: flex;
  }

  .imageContainer{
    height: 260px;
  }

  .cardWrapper img {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    height: 100%;
    width: 246px;
    object-fit: cover;
  }
}

@media (max-width: 1260px) {
  .priceHide {
    display: none;
  }

  .addressSection {
    border-bottom: none;
    padding-bottom: 0;
  }

  .detailsContainer {
    margin-bottom: 0;
  }

  .priceBottomSection {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 16px;
    border-top: 1px solid #dedede;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .priceBottomSection h3::before {
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    background-color: #f66;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -17px;
  }

  .priceBottomSection h3 {
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    position: relative;
  }

  .timeSection h2 {
    width: 100%;
  }

  .timeAdjust {
    width: 50%;
    justify-content: flex-start;
  }

  .upcomingText {
    width: 50%;
    display: flex;
    justify-content: flex-end;
  }
}

@media (max-width: 870px) {
  .cardWrapper {
    flex-direction: column;
  }

  .imageContainer{
    width: 100%;
  }

  .cardWrapper img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 16px;
  }

  .locationSection img {
    object-fit: contain !important;
    width: 15px !important;
    height: 15px !important;
  }

  .locationSection p {
    font-size: 14px;
  }

  .timeSection h2 {
    font-size: 14px;
  }

  .timeSection .titleDate {
    font-size: 14px;
  }

  .timeSection h3 {
    font-size: 14px;
  }

  .timeSection p {
    font-size: 14px;
  }

  .leftDiv .eventTitle {
    font-size: 16px;
  }

  .addressDetails {
    font-size: 14px;
  }

  .capacityDiv {
    font-size: 12px;
  }

  .priceBottomSection h3 {
    font-size: 14px;
  }

  .exactPrice {
    font-size: 14px;
  }

  .exactTime {
    font-size: 14px;
  }
}

@media (max-width: 700px) {
  .priceBottomSection {
    flex-direction: column;
    gap: 12px;
  }
}

@media (max-width: 580px) {
  .cardWrapper img {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }

  .timeSection p {
    width: 104px;
    height: 26px;
  }
}

@media (max-width: 480px) {
  .cardWrapper img {
    width: 100%;
    height: 170px;
    object-fit: cover;
  }

  .imageContainer{
    height: auto;
  }
}

@media (max-width: 450px) {
  .addressDetails {
    width: 60%;
    height: auto;
  }

  .capacitySection {
    width: 60%;
  }

  .bookingNumber p {
    font-size: 14px;
    width: 80vw;
  }
}

@media (max-width: 430px) {
  .timeAdjust {
    width: 100%;
  }

  .timeSection .upcomingText {
    justify-content: flex-start;
  }
}

@media (max-width: 332px) {
  .rightDiv button {
    width: 73vw;
  }
}
