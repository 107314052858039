.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.formContainer {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.formWrapper {
    width: 271px;
}

/* .imageContainer {
    width: 28vw;
} */

.imageContainer .image {
    width: 100%;
    height: 100vh;
    object-fit: cover;
}

@media (min-width: 414px) {
    .formContainer {
        width: 100vw;
    }

    .formWrapper {
        width: 361px;
    }
}

@media (min-width: 767px) {
    .formContainer {
        width: 72vw;
    }

    .formWrapper {
        width: 361px;
    }

    .imageContainer {
        width: 28vw;
    }
}

@media (min-width: 1024px) {
    .formContainer {
        width: 65vw;
    }

    .formWrapper {
        width: 363px;
    }

    .imageContainer {
        width: 35vw;
    }
}

@media (min-width: 1200px) {
    .formContainer {
        width: 46vw;
    }

    .formWrapper {
        width: 367px;
    }

    .imageContainer {
        width: 54vw;
    }
}