

.filterTitle{
    margin-bottom: 25px;
}

.filterTitle h1 {
  color: black;
  font-size: 20px;
  font-family: Roboto;
  font-weight: 500;
 
}

.filterContainer {
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: #ff6666;
  border-radius: 4px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 11px;
  display: inline-flex;
}

.filterContainer h5 {
  color: white;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 600;
}

.filterContainer img {
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: 0.3s all;
}

.filterContainer img:hover {
  transform: scale(1.4);
}

.filterFlex {
  display: flex;
  gap: 9px;
  align-items: center;
  flex-wrap: wrap;
  height: auto;
  padding-bottom: 27px;
  border-bottom: 1px #e1dddd solid;
}

.filterTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filterTitle h4 {
  color: #ff6666;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 600;
  cursor: pointer;
}

.categoriesFilter {
  padding-bottom: 27px;
  border-bottom: 1px #e1dddd solid;
}

.categoriesFilter h1 {
  color: black;
  font-size: 20px;
  font-family: Roboto;
  font-weight: 500;
  margin-top: 24px;
  margin-bottom: 21px;
}

.categoryDiv {
  display: flex;
  gap: 16px;
  align-items: center;
  margin-bottom: 7px;
}

.categoryDiv img {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.categoryDiv h4 {
  color: black;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 400;
}

.categoryCheck {
  padding-left: 12px;
}

.proximityFilter h2 {
  margin-top: 24px;
  color: black;
  font-size: 20px;
  font-family: Roboto;
  font-weight: 500;
  margin-bottom: 21px;
}

.proximityFilter {
  padding-bottom: 20px;
  border-bottom: 1px #e1dddd solid;
}

.eventFilter h2 {
  color: black;
  font-size: 20px;
  font-family: Roboto;
  font-weight: 500;
  margin-top: 24px;
  margin-bottom: 28px;
}

.eventFilter {
  border-bottom: 1px #e1dddd solid;
  padding-bottom: 27px;
}

.ratingFilter h2 {
    color: black;
    font-size: 20px;
    font-family: Roboto;
    font-weight: 500;
    margin-top: 24px;
  }
  
  .ratingFilter {
    padding-bottom: 27px;
  }


.priceFilter{
    margin-top: 24px;
    padding-bottom: 27px;
    
}

.inputFlex{
    display: flex;
    gap: 10px;
}

.submitButton{
    color: white;
font-size: 16px;
font-family: Roboto;
font-weight: 400;
width: 91px;
height: 33px;
padding-left: 20px;
padding-right: 20px;
padding-top: 7px;
padding-bottom: 7px;
background: #FF6666;
border-radius: 4px;
justify-content: center;
align-items: center;
margin-bottom: 23px;
gap: 10px;
display: inline-flex;
margin-top: 10px;
border: none;
}

.closeSymbolDiv{
  display: none;
}

@media (max-width: 975px){
  .closeSymbolDiv{
    display: flex;
    margin-bottom: 34px;
    width: 100%;
  }

  .closeSymbolDiv img{
    margin-left: auto;
    cursor: pointer;
  }
}
