.modalWrapper {
  padding: 40px 20px 20px;
}

.modalWrapper h1 {
  color: #f66;
  text-align: center;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  margin-bottom: 24px;
}

.modalWrapper p {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  width: 331px;
  height: 43px;
  margin-bottom: 24px;
}

.modalWrapper button {

    align-items: center;
    gap: 10px;
    border-radius: 6px;
    background: #f66;
    color: #fff;
    font-family: Poppins;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    width: 183px;
    height: 50px;
    margin-top: 16px;
    left: 50%;
    transform: translateX(-50%);
    
  }
  .profileForm input {
    height: 42px;
    padding: 15px;
    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  
  .profileForm h4 {
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 7px;
  }

  @media (max-width: 500px){
    .modalWrapper h1 {
        font-size: 26px;
        margin-bottom: 16px;
    }

    .modalWrapper p {
        width: 70vw;
    }

    .profileForm input {
        height: 32px;
      }

      .profileForm h4 {
        font-size: 14px;
      }

      .modalWrapper button{
        margin-top: 10px;
        font-size: 14px;
        width: 140px;
        height: 40px;
      }
  }

  @media (max-width: 370px){
    .modalWrapper h1 {
        font-size: 20px;
    }
  }

  @media (max-width: 300px){
    .modalWrapper h1 {
        font-size: 18px;
    }

    .modalWrapper p {
        font-size: 10px;
    }
  }
