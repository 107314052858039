.container {
    width: 94%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 33px;
    padding-bottom: 100px;

}

.privacypolicy {
    border-radius: 20px;
    box-shadow: 0 448px 125px rgba(0, 0, 0, 0), 0 287px 115px rgba(0, 0, 0, 0.01), 0 161px 97px rgba(0, 0, 0, 0.03), 0 72px 72px rgba(0, 0, 0, 0.04), 0 18px 39px rgba(0, 0, 0, 0.05);
    background-color: #fff;
    padding: 40px;
}

.privacypolicy a {
    color: blue;
}

.privacypolicy a:hover {
    text-decoration: underline;
}

.policyheader {
    text-align: center;
    margin-bottom: 40px;
}

.policytitle {
    font-family: poppins, sans-serif;
    font-size: 24px;
    color: #282828;
    font-weight: 700;
    text-transform: capitalize;
    line-height: 104%;
}

.policycontent {
    font-size: 16px;
    color: #282828;
    font-weight: 500;
    line-height: 23px;
}

.effectivedate {
    font: 600 12px/108% Georama, -apple-system, Roboto, Helvetica, sans-serif;
    margin-bottom: 20px;
}

.policyintroduction {
    font-family: Georama, sans-serif;
    line-height: 20px;
    margin-bottom: 20px;
}

.sectiontitle {
    font-family: Georama, sans-serif;
    font-weight: 700;
    line-height: 112.5%;
    text-transform: capitalize;
    margin-top: 20px;
    margin-bottom: 20px;
}

.subsectiontitle {
    font-family: Georama, sans-serif;
    font-weight: 700;
    line-height: 112.5%;
    text-transform: capitalize;
    margin-top: 20px;
    margin-bottom: 20px;
}

.sectioncontent {
    font-family: Georama, sans-serif;
    line-height: 20px;
    margin-bottom: 20px;
}

.description ul,
.description ol {
    /* Default for LTR */
    list-style-position: outside;
}

/* Specific styles for RTL direction */
.description:dir(rtl) ul,
.description:dir(rtl) ol {
    padding-left: 0;
    /* Reset left padding */
    padding-right: 40px;
    /* Add right padding for RTL */
}

.description ul li,
.description ol li {
    /* Default for LTR */
    line-height: 1.5;
}

/* Specific styles for RTL direction */
.description:dir(rtl) ul li,
.description:dir(rtl) ol li {
    margin-left: 0;
    /* Reset left margin */
    margin-right: 20px;
    /* Add right margin for RTL */
}

@media (max-width: 1024px) {
    .policycontent {
        font-size: 14px;
        line-height: 20px;
    }
}

@media (max-width: 991px) {
    .privacypolicy {
        padding: 20px;
    }

    .policytitle {
        padding: 0 20px;
    }
}

@media (max-width: 757px) {
    .policycontent {
        font-size: 12px;
        line-height: 18px;
    }
}

@media (max-width: 480px) {
    .policycontent p {
        margin-left: 30px !important;
        margin-right: 30px !important;
    }
}