.reviewContainer {
    width: 100%;
    border-radius: 16px;
    border: 1px solid #E8E8E8;
    margin-top: 20px;
    font-family: 'Poppins', sans-serif;
}

.reviewWrapper {
    display: flex;
    flex-direction: column;
}

.breakLine {
    content: "";
    display: inline-block;
    width: 100%;
    height: 0.8px;
    background-color: #DEDEDE;
    /* margin-top: 12px; */
    /* margin-bottom: 20px; */
}

.ratingWrapper {
    width: 100%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.heading {
    color: #000;
    font-size: 1rem;
    font-weight: 500;
    line-height: normal;
    margin-right: auto;
}

.rating {
    margin-top: 28px;
    color: #000;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: normal;
}

.rating span {
    color: #000;
    font-size: 2.5rem;
    font-weight: 700;
    line-height: normal;
}

.ratingStars {
    margin-top: 13px;
}

.numbers {
    color: #4F595B;
    text-align: center;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: normal;
    margin-top: 20px;
}

.ratingMessage {
    color: #4F595B;
    text-align: center;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: normal;
    margin-top: 25px;
}

.commentsWrapper {
    width: 100%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
}

.reviewBox {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #DEDEDE;
    margin-bottom: 24px;
}

.personDetails {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.profilePhoto {
    content: "";
    width: 50px;
    height: 50px;
    background-color: #D9D9D9;
    border-radius: 50%;
}

.person {
    color: #5D5C5C;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: normal;
    margin-left: 16px;
}

.person span {
    color: #000;
    font-size: 1rem;
    font-weight: 500;
    line-height: normal;
}

.personRating {
    display: flex;
    align-items: center;
    padding: 4px 6px;
    border-radius: 4px;
    background: #F66;
    color: #FFF;
    font-size: 1rem;
    font-weight: 500;
    line-height: normal;
    margin-left: 20px;
}

.starBox {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2px;
    margin-right: 2px;
}

.comment {
    margin: 24px 0 24px 6px;
    color: #6B6868;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: normal;
}

@media (min-width: 767px) {
    .rating {
        font-size: 1.875rem;
    }

    .rating span {
        font-size: 3.75rem;
    }

    .numbers {
        font-size: 1rem;
        margin-top: 24px;
    }

    .ratingMessage {
        font-size: 1rem;
        margin-top: 40px;
    }

    .comment {
        margin: 30px 40px 24px 6px;
        font-size: 1rem;
    }
}

@media (min-width: 1024px) {
    .reviewWrapper {
        display: flex;
        flex-direction: row;
    }

    .reviewWrapper .verticalLine {
        content: "";
        /* height: 100%; */
        width: 1px;
        background-color: #E8E8E8;
        /* margin: 0 24px; */
    }

    .ratingWrapper {
        width: 359px !important;
    }

    .rating {
        margin-top: 42px;
    }

    .ratingMessage {
        font-size: 1rem;
        margin-top: 48px;
    }

    .commentsWrapper {
        /* flex: 1 - it will take remaining space after 1st columnn */
        flex: 1;
    }
}

@media (min-width: 1200px) {}