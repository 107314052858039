.galleryContainer {
    width: 88.3%;
    margin: 40px auto 60px auto;

}

.galleryWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    font-family: 'Poppins', sans-serif;
}

.topSection {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}

.titleSection {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

.title {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: normal;
    color: #000;
}

.description {
    color: #000;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: normal;
}

.galleryGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
}

.image {
    border-radius: 8px;
}

.image>.ant-image-mask {
    border-radius: 8px;
}


.venueImage {
    width: 100%;
    /* min-height: 186px; */
    border-radius: 8px;
    background-color: #D9D9D9;
    position: relative;
    cursor: pointer;
}

.playButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
}

.thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.videoModal {
    width: 95vw !important;
}

.modalBody {
    height: 30vh !important;
}

@media (min-width: 767px) {
    .title {
        font-size: 1.75rem;
    }

    .galleryGrid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 12px;
    }

    .videoModal {
        width: 95vw !important;
    }

    .modalBody {
        height: 50vh !important;
    }
}

@media (min-width: 1024px) {
    .topSection {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .title {
        font-size: 2rem;
    }

    .description {
        font-size: 1rem;
    }

    .galleryGrid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 12px;
    }

    .videoModal {
        width: 60vw !important;
    }

    .modalBody {
        height: 60vh !important;
    }
}

@media (min-width: 1200px) {
    .galleryGrid {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 12px;
    }
}