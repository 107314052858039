.infoContainer {
    width: 100%;
    border-radius: 16px;
    border: 1px solid #E8E8E8;
    padding: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 13px;
    font-family: 'Poppins', sans-serif;
}

.leftSection {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
}

.name {
    font-size: 1rem;
    font-weight: 600;
    line-height: normal;
}

.cityWrapper {
    color: #5D5C5C;
    display: flex;
    flex-direction: row;
    column-gap: 6px;
    align-items: center;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: normal;
}

.cityWrapper .cityIcon {
    width: 13px;
    height: 12px;
}

.address {
    color: #5D5C5C;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    cursor: pointer;
}

.rightSection {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.iconWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 12px;
    margin-left: auto;
}

.ratingWrapper {
    display: flex;
    flex-direction: row;
    column-gap: 8px;
}

.ratingWrapper h1 {
    color: #5D5C5C;
    font-size: 1rem;
    font-weight: 500;
    line-height: normal;
}

.shareIcon {
    cursor: pointer;
}

.shareButtonsContainer {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: center; */
    gap: 15px;
    margin: 30px 0 10px 0;
}


.loadingIcon {
    color: #FF6666;
    /* margin: auto 0 auto 0; */
}


.wishlistIcon {
    cursor: pointer;
}

@media (max-width: 390px) {
    .ratingWrapper {
        display: flex;
        flex-direction: column;
        /* column-gap: 8px; */
    }
}

@media (min-width: 767px) {
    .infoContainer {
        padding: 24px;
        flex-direction: row;
    }

    .leftSection {
        /* margin-right: 30px; */
        padding-right: 30px;
        width: 100%;
    }

    .name {
        font-size: 1.125rem;
    }

    .cityWrapper {
        font-size: 1rem;
    }

    .cityWrapper .cityIcon {
        width: 19px;
        height: 18px;
    }

    .address {
        font-size: 1rem;
    }


    .rightSection {
        /* width: 237px; */
        flex-direction: column;
    }

    .ratingWrapper {
        margin-left: auto;
    }
}

@media (min-width:1024px) {
    .name {
        font-size: 1.25rem;
    }
}

@media (min-width:1200px) {
    .name {
        /* font-size: 20px; */
    }
}